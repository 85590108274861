.waves{
  width: 1.5px;
  height: 30px;
  display:inline-block;
  margin: 1px;
  transition: all 1s;
  
}

.wavecontainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}