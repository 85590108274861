.datepicker-modal {
  position: relative;
  width: 266px;
  height: 174px;
  flex-grow: 0;
  margin: 7px 0 0;
  padding: 11px 37px 11px 20px;
  border-radius: 12px;
}

.datepicker.android .datepicker-scroll li,
.datepicker.android-dark .datepicker-scroll li {
  font-size: 16px !important;
  font-family: 'Open Sans' !important;
  text-align: center !important;
  color: #fff !important;
}
.datepicker-content {
  height: 174px;
  border-radius: 12px;
}
.datepicker .datepicker-content {
  background-color: #281f42;
}
.datepicker.android .datepicker-wheel,
.datepicker.android-dark .datepicker-wheel {
  border: none !important;
}
.datepicker .datepicker-viewport {
  height: 163px !important;
  background-color: var(--dark-indigo);
}
.datepicker.android-dark {
  background-color: var(--dark-indigo) !important;
}
.datepicker.android .datepicker-col-1,
.datepicker.android-dark .datepicker-col-1 {
  margin: 0px !important;
}
.datepicker {
  position: absolute;
  top: 3px;
  width: 266px;
  height: 173px;
  border-radius: 12px;
}
