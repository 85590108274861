.click-disabled *:not(#recordImage) {
  cursor: not-allowed !important;
}

.click-disabled #root *:not(#recordImage) {
  pointer-events: none;
}

#recordImage {
  pointer-events: auto;
  cursor: pointer;
}

.recording-animation {
  animation: pulse 1.5s infinite linear;
}

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 5px 0px var(--barbie-pink);
    /* transform: scale(1); */
  }
  65% {
    box-shadow: 0px 0px 5px 13px var(--barbie-pink);
  }
  90% {
    box-shadow: 0px 0px 5px 13px var(--barbie-pink);

  }
  100% {
    box-shadow: 0px 0px 5px 0px var(--barbie-pink); /* Matching the starting state */

    opacity: 1;
  }
}
