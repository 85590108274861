@use '/src/common/variables.scss';
@use '/src/common/MobileDatePicker.scss';

@mixin custom-scrollbar {
  scrollbar-color: #352b75 transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #87858f;
    border-radius: 5px;
  }
}

@media screen and (max-width: 600px) {
  .w-1000 {
    width: 300px;
  }
  .w-100 {
    width: 20rem;
  }
  .w-320 {
    width: 6rem;
  }}
@media screen and (min-width: 601px) and (max-width: 1024px) {

  .w-100 {
    width: 20rem;
  }
  .w-320 {
    width: 7rem;
  }
 
}
  .w-1000 {
    width: 20rem;
  }
@media screen and (min-width: 1025px) {
  
  .w-100 {
    width: 20rem;
  }
  .w-1000 {
    width: 40.5rem;
  }
  .w-320 {
     width: 10rem;
  }}
* {
  color: white; 
  
  @include custom-scrollbar;
}
.custom-placeholder {
  color: black !important
}
button {
  svg {
    path {
      color: unset;
    }
  }
}

.chakra-modal__content-container {
  overflow: unset !important;
}

// .Dropdown-control.w-100 {
//   width: 100px;
// }
.Dropdown-control.w-150 {
  width: 150px;
}
.Dropdown-control.w-200 {
  width: 200px;
}
.Dropdown-control.w-300 {
  width: 300px;
}

.Dropdown-control.w-500 {
  width: 500px;
}

.Dropdown-control.custom-control {
  background-color: #ffffff;
  border: none;
  border-radius: 12px;
  padding: 15px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: none;
}

 .is-open .Dropdown-control.custom-control{
  border: none;
  // border-radius: top-left, top-right, bottom-left, bottom-right
  border-radius: 0px 0px 16px 16px;
  padding: 15px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
 
.Dropdown-arrow-wrapper .arrow-closed {
  transform: rotate(0deg);
}

.Dropdown-arrow-wrapper .arrow-open {
  transform: rotate(180deg);
}

.Dropdown-menu.custom-menu {
  margin-top: -10px;
  background-color: #ffffff;
  border-radius: 16px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: none;
  border-top: 1px solid #7762eb;
  padding: 5px 15px;
  .Dropdown-option {
    background: white;
    color: rgb(0, 0, 0);
    &:hover,
    &.is-selected {
      background: #c3c1ca;
      font-weight: 600;
      border-radius: 8px;
    }
  }
}

// Sidemenu variant

.Dropdown-control.w-210 {
  width: 210px;
  font-size: 13px;
}

.is-open .Dropdown-control.w-210 {
  width: 210px;
  font-size: 13px;
  border: none;
  border-radius: 0px 0px 16px 16px;
  padding: 15px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: none;
}

.Dropdown-menu.custom-menu.top {
  top: auto;
  bottom: 100%;
  border: none;
  border-bottom: 1px solid #7762eb;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 13px;
}

// Remove highlight box on tapped elements
* {
  -webkit-tap-highlight-color: transparent;
}

.Dropdown-root {
  position: relative;
  color: #000000;
}

.Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  color: #333;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
}

.Dropdown-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Dropdown-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.Dropdown-menu {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 20vh;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 8px 10px;
  color: rgba(51, 51, 51, 1);
  font-weight: bold;
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-option.is-selected {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-noresults {
  box-sizing: border-box;
  color: #000000;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

.addError{
  border: 2px solid red !important;
}

.welcome-wrapper  .is-open .Dropdown-control.custom-control{
  border-radius: 12px;
}

.welcome-wrapper .Dropdown-arrow{
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    top: 44%;
    transform: translate(-50%, -50%) rotate(45deg);
    ;
}

.welcome-wrapper .is-open .Dropdown-arrow{
  -webkit-transform: rotate(-135deg);
  top: 44%;
  transform: translate(-50%, -50%) rotate(-135deg);
}

/* CSS for the click animation while choosing image */
// animations are repeated, because, when given together, it doesnt work.


.image-clicked {
  animation: clickAnimation 0.4s ease;
}

.image-unselected {
  animation: clickAnimationUnselected 0.4s ease;
}

@keyframes clickAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes clickAnimationUnselected {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

//animation for audio icon
.animateAudioIcon {
  animation: scaleUpDown 0.4s ease infinite;
}

@keyframes scaleUpDown {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
//Audio icon hover styles 
.soundIcon:hover{
  outline: 1px solid rgb(255, 255, 255,0.5);
}

.info-icon-menu svg{
  stroke-width: 0.5px
}

//instruction modal styles 

.instruction-modal-wrapper{
  width: 700px;
}
.instruction-modal-wrapper p{
  font-size: 13px;
  line-height: 24px;
}

//welcome page
.welcome-wrapper {
  label,.custom-placeholder{
  font-size: 13px;
  }
  .followup-dropdown{
    .custom-control{
      width: 640px;
      height:44px;
    }
  }
  .w-date-picker{
    width: 197px;
    height: 44px;
  }
  .w-preschool-department{
    width:304px;
    height: 44px;
  }
  div{
    margin-top:0;
  }
 
}
.checkbox-instruction-text{
  span{
    font-size: 14px;
  }
}

.welcome-root, .welcome-root>div {
  background-color: rgb(21, 7, 58);
}

.welcome-root .header-wrapper{
  width: 100vw;
  padding-left:var(--chakra-space-12);
  padding-right:var(--chakra-space-12);
  background-color: var(--chakra-colors-darkIndigo);
}
.welcome-root .router-wrapper{
  height: 100vh;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.age-selector-box {
  div{
    height: 44px;
  }
  label{
    div{
      align-items: center;
      height: 40px;
    }
  }
}
.welcome-wrapper .Dropdown-menu.custom-menu{
  padding-left:0;
  padding-right:0;
}
.welcome-wrapper .Dropdown-menu.custom-menu .Dropdown-option {
  font-size: 13px;
  padding-left: 16px;
}

.video-language-selector {
  button {
    background: transparent;
    box-shadow: none;

    &:active,
    &[data-active] {
      background: transparent;
    }

    &:hover,
    &[data-hover] {
      background: transparent;
    }

    &:focus,
    &[data-focus] {
      background: transparent;
    }
  }
}

// all buttons have a blue outline when clicked in chakra. It is a default behavior. We can remove it with this style.
*:focus {
  box-shadow: none!important;
}


.skip-options-radio span{
  color: black;
  outline: none;
  box-shadow: none!important;
}


.release-notes-wrapper{
  height: max-content !important;
  max-height: max-content !important;
  
}
.release-notes-outer{
  height: max-content !important;
}
.release-notes-wrapper ~ div {
  display: none;
}

.release-root-wrapper{
  background-color: var(--chakra-colors-darkIndigo) !important;
}

//physics task responsive css

@media screen and (min-height: 600px) and (max-height: 910px) and (min-width: 1200px){
 
  .bowlImageWrapper{
    height: 87% !important;
    img{
      height: 44vh !important;
    }
  }


}

@media screen and (min-height: 600px) and (max-height: 910px) and (min-width: 1535px){
 
  .bowlImageWrapper{
    height: 80% !important;
    img{
      height: 40vh !important;
    }
  }
  .optionsWrapper>div {
    width: 11vw;
    height: 10vw;
    margin-top: 5px;
}
.physics-outer-wrapper{
  height: fit-content !important;
}
.physics-wrapper{
  height: fit-content !important;
}
.physics-flex{
  margin-bottom:20px;
}

}

