.fullscreen-wrapper {    
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.minimise-image {
    width: 24px;
    height: 24px;
    transition: width 0.3s, height 0.3s; /* Adding transition for width and height */
}

img:hover {
    opacity: 0.7;
}

.minimise-image:hover {
    width: 18px;
    height: 18px;
}
